<template lang="html">
  <transition mode="out-in">
    <span v-if="loading">Loading...</span>
    <div v-else class="ecology-grassland">
      <EcologyInputGrid>
        <DlgGridHeader>
          1. <IxRes>dlgCert.ecology.grassland.headers.extensive</IxRes>
        </DlgGridHeader>
        <DlgGridHeader>
          <IxRes>dlgCert.ecology.grassland.headers.dispenseChemicalActions</IxRes>
        </DlgGridHeader>
        <EcologyInputLine
          :data="data" name="withoutNitrogenFertilizer"
          show-percent-of="grassland"
        />
        <EcologyInputLine
          :data="data" name="withoutNaturalPlantProtection"
          show-percent-of="grassland"
        />
        <DlgGridHeader>
          <IxRes>dlgCert.ecology.grassland.headers.reducedTillage</IxRes>
        </DlgGridHeader>
        <EcologyInputLine
          :data="data" name="withoutPulling"
          show-percent-of="grassland"
        >
          <template #description>
            <IxRes>dlgCert.ecology.grassland.descriptions.PullingDescription</IxRes>
          </template>
        </EcologyInputLine>
        <DlgGridHeader>
          <IxRes>dlgCert.ecology.grassland.headers.mowing</IxRes>
        </DlgGridHeader>
        <EcologyInputLine
          :data="data" name="companyAltitude"
          unit="m"
          :rule="x => x >= 0"
          @input="checkAltitute"
        />
        <EcologyInputLine
          :data="data" name="averageCuttingGrassland"
          unit=""
        />
        <EcologyInputLine
          :data="data" name="parcelCutting"
          show-percent-of="grassland"
          @input="update"
        />
        <EcologyInputLine
          :data="data" name="shareOfFirstCutting"
          show-percent-of="grassland"
        />

        <DlgGridHeader>
          2. <IxRes>dlgCert.ecology.grassland.headers.features</IxRes>
        </DlgGridHeader>
        <DlgGridHelpBox>
          <IxRes>dlgCert.ecology.grassland.hints.features</IxRes>
        </DlgGridHelpBox>
        <EcologyInputLine
          :data="data" name="landscapeFeaturesTotalArea"
          show-percent-of="total"
          @input="update"
        />
        <EcologyInputLine
          :data="data" name="additionalFeaturesTotalArea"
          show-percent-of="total"
          @input="update"
        >
          <template #description>
            <LandscapeFeatureDescription />
          </template>
        </EcologyInputLine>
        <EcologyInputLine
          :data="data" name="uniqueLandscapeFeatures"
          unit=""
          @input="update"
        >
          <template #description>
            <IxRes>dlgCert.ecology.grassland.descriptions.DifferentFeatureCountDescription</IxRes>
          </template>
        </EcologyInputLine>

        <DlgGridHeader>
          3. <IxRes>dlgCert.ecology.grassland.headers.ecologicalPriorityAreas</IxRes>
        </DlgGridHeader>
        <EcologyInputLine
          v-for="name in ['fallowLand',
                          'stoneFruitMeadow',
                          'continuousGrazing']" :key="name"
          :data="data" :name="name"
          show-percent-of="grassland"
          @input="update"
        />
        <EcologyInputLine
          v-for="name in ['speciesPromotion']" :key="name"
          unit=""
          :data="data" :name="name"
          @input="update"
        />
      </EcologyInputGrid>
    </div>
  </transition>
</template>

<script>
import EcologyInputGrid from './elements/EcologyInputGrid'
import DlgGridHeader from './elements/DlgGridHeader'
import DlgGridHelpBox from './elements/DlgGridHelpBox'
import EcologyInputLine from './elements/EcologyInputLine'
import LandscapeFeatureDescription from './shared/LandscapeFeatureDescription'

import ApiMixin from '@dlg-cert/mixins/ApiMixin'

export default {
  components: {
    LandscapeFeatureDescription,
    EcologyInputGrid,
    DlgGridHeader,
    DlgGridHelpBox,
    EcologyInputLine
  },
  mixins: [
    ApiMixin
  ],
  computed: {
    apiUrl () {
      return `/api/v2/dlg-cert/${this.$route.params.orgUnitId}/ecology/${this.$route.params.year}/grassland`
    }
  },
  methods: {
    checkAltitute (event) {
      if (event.value >= 0) {
        this.update(event)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-active, .v-leave-active {
  transition: opacity 0.3s;
}
</style>
