<template>
  <div>
    <li v-for="item in description" :key="item">
      <IxRes>{{ `dlgCert.ecology.descriptions.landscapeDescription.${[item]}` }}</IxRes>
    </li>
  </div>
</template>

<script>
export default {
  computed: {
    description () {
      return [
        'rowOfTrees',
        'tree',
        'woodland',
        'banks',
        'naturalStone',
        'habitat',
        'ditchInOtherStates',
        'hedge',
        'terrace',
        'stoneWalls',
        'moore',
        'shoreVegetation'
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
