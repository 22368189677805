<script>
import HelpBox from '@components/help/HelpBox'

export default {
  functional: true,
  render (h, context) {
    return [
      // NOTE `aside` wrapper is used to prevent interference with EcologyInputLine background colors
      h('aside', {
        class: 'dlg-grid-help-box'
      }, [
        h(HelpBox, [context.scopedSlots.default()])
      ])
    ]
  }
}
</script>

<style lang="scss" scoped>
.dlg-grid-help-box {
  margin: 0;
  grid-column: span 3;

  > * {
    margin: 0;
  }

  &:not(:first-child) {
    margin: 1em 0;
  }
}
</style>
